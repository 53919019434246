import Selectr from 'mobius1-selectr/dist/selectr.min';

const initCustomSelects = (mainElement = document) => {
    const customSelects = [...mainElement.querySelectorAll('.js-custom-select')];
    if (customSelects) {
        customSelects.forEach(element => {
            new Selectr(element, {
                searchable: false,
                width: 300
            });
        });
    }
};

export default initCustomSelects;