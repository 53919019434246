const Swal = require('sweetalert2')

const initAuthCheck =  () => {
    const requestCookies = document.cookie;

    const swalFire = async () => {
        const { value: password } = await Swal.fire({
            title: 'Enter password',
            input: 'password',
            confirmButtonText: 'Yes, Confirm!',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            inputPlaceholder: 'Enter password',
            inputAttributes: {
              maxlength: 20,
              autocapitalize: 'off',
              autocorrect: 'off',
              required: 'true'
            },
            allowOutsideClick: false,
            allowEscapeKey: false
        })
     
        if(btoa(password) === internal_password){
            localStorage.setItem("auth", btoa(password))
            window.location.href =  base_url+'internal/overview/' ;
        }else{
            window.location.href =  base_url+'internal/' ;
        }          
    }

    if(window.location.pathname === '/internal/' || window.location.pathname === '/internal'){

        if(localStorage.getItem("auth") && localStorage.getItem("auth") == internal_password){
            window.location.href =  base_url+'internal/overview/' ;
        }else{
            swalFire();
        }
    }
    
    // if (
    //     navigator.userAgent.includes('Algolia Crawler') === false
    //     && (
    //         requestCookies === false || requestCookies.indexOf('isAuthenticated=' + tixstock_session) != 0
    //     )
    // ) {
    //     window.location.href = reseller_url;
    // }

    document.querySelector('body').classList.add('isAuthed');

};

export default initAuthCheck;
