const initVersionSwitcher = () => {
    const versionSwitch = document.querySelector('#js-version-switcher');
    if (versionSwitch) {
        versionSwitch.addEventListener('change', function () {
            return location.href = versionSwitch.value;
        });
    }
};

export default initVersionSwitcher;
